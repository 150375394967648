<template>
    <nav
        v-if="socials.length"
        class="socials"
    >
        <a
            v-for="social in socials"
            :key="social.name"
            class="socials__social"
            :href="social.url"
            target="blank"
            rel="noopener"
            :aria-label="`Go to our ${social.name} page`"
        >
            <BaseIcon
                :icon="social.icon"
                :auto-color="false"
            />
        </a>
    </nav>
</template>

<script setup>
import SiteSocialLinksQuery from '~/graphql/queries/global/SiteSocialLinks.graphql';

const { activeSite } = useMultisite();
const { variables: defaultVariables } = useCraftGraphql();

delete defaultVariables.uri;

const variables = ref({
    ...defaultVariables,
    site: toValue(activeSite).handle
});

const { data } = await useLazyAsyncQuery({
    query: SiteSocialLinksQuery,
    variables
});

const socials = computed(() => {
    const socialLinks = data.value?.site?.socialLinks.filter((social) => social.url);

    return socialLinks ?? [];
});
</script>

<style lang="less">
.socials {
    display: flex;
}

.socials__social {
    color: var(--color-text-dark);
    font-size: var(--font-size-2xl);

    & + .socials__social {
        margin-left: 1.25rem;
    }
}
</style>
