<template>
    <div class="hamburger-menu">
        <button
            type="button"
            class="hamburger-menu__button button--transparent button--icon-only"
            aria-label="Toggle menu"
            @click="$emit('toggleMenu')"
        >
            <span
                class="hamburger-menu__bun"
                aria-hidden="true"
            />
            <span
                class="hamburger-menu__bun"
                aria-hidden="true"
            />
            <span
                class="hamburger-menu__bun"
                aria-hidden="true"
            />
        </button>
    </div>
</template>

<style lang="less" src="./TheHamburgerMenu.less"></style>
