<template>
    <BaseFilterSelect
        v-if="languages?.length"
        id="main-language-select"
        v-model="language"
        :options="languages"
        :placeholder="language?.title ?? 'Language'"
        :searchable="true"
        class="language-select"
        :track-by-property="'uri'"
        @update:model-value="goToLanguage($event)"
    />
</template>

<script setup>

const languages = useLanguages();

const language = ref(null);
const route = useRoute();

onMounted(() => {
    language.value = toValue(languages)?.find(lang => lang.active);
});

const goToLanguage = async(langUri) => {
    alert(langUri);
    if (!langUri) {
        return;
    }
    language.value = toValue(languages).find(lang => lang.uri === langUri);
    let targetUrl = langUri.startsWith('http') ? langUri : `http://${langUri}:3000`;
    if (route.path !== '/') {
        targetUrl += route.path;
    }
    await navigateTo(targetUrl, { external: true });
};
</script>
