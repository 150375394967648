<template>
    <div class="uro-notice">
        <div class="uro-notice__container">
            {{ message }}

            <template v-if="readmoreLink !== ''">
                <nuxt-link
                    :to="`/${readmoreLink}`"
                    class="uro-notice__action"
                >
                    Read more
                    <BaseIcon icon="chevron-right" />
                </nuxt-link>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            required: false,
            default: ''
        },

        readmoreLink: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style lang="less">
    .uro-notice {
        background: var(--color-secondary-light);
    }

    .uro-notice__container {
        .page-container(true, 1920px);
        margin: auto;
        padding-block: var(--spacing-xs);
    }

    .uro-notice__action {
        .typography-label-secondary;

        font-size: 1em;
        line-height: .5em;
        text-decoration: none;

        margin-left: .25rem;

        .dn-icon {
            transform: translateX(0) translateY(2px);
            font-size: 1.16667em;
        }

        @media @q-lg-min {
            font-size: 1em;
        }
    }
</style>
