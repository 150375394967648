<template>
    <div
        v-show="isVisible"
        class="navigation-submenu"
    >
        <div class="navigation-submenu__container">
            <nav
                v-if="primaryItems && primaryItems.length"
                class="navigation-submenu__left"
            >
                <TheNavigationLink
                    v-for="(item, index) in primaryItems"
                    :key="`primary${index}`"
                    :title="item.title"
                    :subtitle="item.subtitle"
                    :icon="mapCmsIcon(item.icon)"
                    :is-sub="true"
                    :to="getUri(item)"
                />
            </nav>

            <nav
                v-if="secondaryItems && secondaryItems.length"
                class="navigation-submenu__right"
            >
                <TheNavigationLink
                    v-for="(item, index) in secondaryItems"
                    :key="`secondary${index}`"
                    :title="item.title"
                    :is-sub="true"
                    :to="getUri(item)"
                />
            </nav>
        </div>
    </div>
</template>
<script setup>
defineProps({
    isVisible: {
        type: Boolean,
        required: true,
        default: false
    },
    primaryItems: {
        type: Array,
        required: false,
        default: null
    },
    secondaryItems: {
        type: Array,
        required: false,
        default: null
    }
});

const { mapCmsIcon } = useCmsIcon();

function getUri(item) {
    if (item.internal && item.internal.length) {
        return `/${item.internal[0]?.uri}`;
    }

    return item.external;
}
</script>

<style lang="less">
.navigation-submenu {
    @media @q-lg-min {
        position: absolute;
        top: 5rem;
        left: 0;
        right: 0;

        background: var(--color-lightest);
        border-top: 1px solid var(--color-gray-200);
        box-shadow: 0 1px 1px rgba(0, 0, 0, .08);
    }
}

.navigation-submenu__container {
    .page-container(true, 1920px);

    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 2rem 0;

    .navigation-submenu__right {
        margin-top: 2rem;
    }

    @media @q-md-min {
        margin-bottom: 0;
    }

    @media @q-lg-min {
        flex-direction: row;
        margin: 0 auto;
        padding-block: 3.25rem;

        .navigation-submenu__right {
            margin-top: 0;
            margin-left: 1.875rem;
        }
    }
}

.navigation-submenu__left + .navigation-submenu__right {
    @media @q-lg-min {
        padding-left: 1.875rem;
        border-left: 1px solid var(--color-secondary-light);
    }
}
</style>
