<template>
    <span class="footer-link">
        <template v-if="item.type === 'link' && item.uri">
            <component
                :is="checkIsExternal(item.uri) ? 'a' : NuxtLink"
                :to="!checkIsExternal(item.uri) ? item.uri : undefined"
                :href="checkIsExternal(item.uri) ? item.uri : undefined"
                :target="checkIsExternal(item.uri) ? 'blank' : 'self'"
                :rel="checkIsExternal(item.uri) ? 'noopener noreferrer' : undefined"
            >
                {{ item.text }}
            </component>
        </template>

        <template v-else-if="item.type === 'button' && item.uri">
            <BaseButton
                :element="NuxtLink"
                :to="item.uri"
            >
                {{ item.text }}
            </BaseButton>
        </template>

        <template v-else-if="item.type === 'html' && item.html">
            <div v-html="item.html" /> <!-- eslint-disable-line vue/no-v-html -->
        </template>

        <template v-else>
            {{ item.text }}
        </template>
    </span>
</template>

<script setup>
const { checkIsExternal } = useUri();

const NuxtLink = resolveComponent('NuxtLink');

const { item } = defineProps({
    item: {
        type: Object,
        required: true,
        default: null
    }
});
</script>

<style lang="less" src="./TheFooterLink.less" />
